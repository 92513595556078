<!--
 * @Description: 房屋分组
 * @Author: 琢磨先生
 * @Date: 2022-08-18 08:31:14
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-08-19 21:57:33
-->

<template>
  <el-card class="box">
    <el-form :model="query" ref="query" :inline="true"></el-form>
    <div>
      <el-button
        type="primary"
        size="small"
        @click="onEdit()"
        round
        icon="plus"
        v-if="is_can_edit"
      >新增房型</el-button>
      <el-button type="success" size="small" @click="editStore()" round icon="plus">添加房间</el-button>
    </div>
  </el-card>
  <el-card class="box data" v-loading="loading">
    <el-table :data="data_list" border>
      <el-table-column label prop="name" fixed="left" width="300">
        <template #header>
          <b style="margin-right: 10px">房型</b>
          <el-button
            size="small"
            round
            icon="Tickets"
            @click="openDraggable(data_list)"
            v-if="is_can_sort"
          >排序</el-button>
        </template>
        <template #default="scope">
          <div>{{ scope.row.name }}</div>
          <div
            style="font-size: 12px; color: #999"
          >({{ scope.row.shop_name }})、共{{ scope.row.store_list.length }}间</div>
        </template>
      </el-table-column>
      <el-table-column>
        <template #header>房间号{{ room_count_text }}</template>
        <template #default="scope">
          <div class="store_row" v-for="item in scope.row.store_list" :key="item.id">
            <span class="house_id">ID:{{ item.id }}</span>
            <span class="house_no">
              <b>{{ item.house_no }}</b>
              ({{ item.landlord.name }})
            </span>
            <span
              class="fen"
              style="padding-right: 20px;"
            >业主分成比例：{{ item.income.decimal_income_rate }}%</span>
            <span class="date">签约日期：{{ item.signed_date }}</span>
            <span class="date">售卖日期：{{ item.sell_date }}</span>
            <span class="status">
              <el-tag type="danger" v-if="item.is_stop" size="small">已停用</el-tag>
            </span>
            <el-button
              type="primary"
              link
              icon="view"
              size="small"
              @click="viewStoreDtl(item.id)"
              v-if="is_can_edit"
            >详情</el-button>
            <el-button
              type="primary"
              link
              icon="edit"
              @click="editStore(item)"
              v-if="is_can_edit_store"
            >修改</el-button>
            <el-popconfirm
              title="删除后不可恢复，确定要删除？"
              @confirm="onDeleteStore(item)"
              v-if="is_can_del_store"
            >
              <template #reference>
                <el-button type="danger" link size="small" icon="delete"></el-button>
              </template>
            </el-popconfirm>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="240">
        <template #default="scope">
          <el-button
            type="primary"
            link
            size="small"
            @click="onEdit(scope.row)"
            v-if="is_can_edit"
          >修改</el-button>
          <!-- <el-button
            type="success"
            link
            size="small"
            icon="plus"
            @click="editStore(scope.row)"
            >添加房间</el-button
          >-->
          <el-button
            type="info"
            size="small"
            round
            @click="onStoreSort(scope.row)"
            v-if="is_can_sort_store"
          >房间排序</el-button>
          <el-popconfirm
            title="确定要删除？"
            @confirm="onDelete(scope.row)"
            v-if="is_can_del && scope.row.store_list.length == 0"
          >
            <template #reference>
              <el-button
                size="small"
                type="danger"
                link
                style="margin-top: 10px; margin-left: 0px"
              >删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </el-card>

  <el-dialog
    :title="title"
    v-model="visibleDialog"
    width="600px"
    draggable
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-alert
      type="error"
      style="margin-bottom: 30px"
      title="如果修改门店，则房型下的所有房间将同时转移至新门店、且会移除与房源关联关系！"
      :closable="false"
    ></el-alert>
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-form-item label="门店" prop="shop_id">
        <el-select v-model="form.shop_id" placeholder>
          <el-option v-for="item in shop_list" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="房型名称" prop="name">
        <el-col :span="15" :offset="0">
          <el-input v-model="form.name" placeholder></el-input>
        </el-col>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button type="primary" @click="onSubmit" :loading="saving">确定</el-button>
    </template>
  </el-dialog>

  <el-dialog
    title="排序(拖拽排序)"
    v-model="visibleSortDialog"
    width="600px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div>
      <draggable item-key="id" v-model="drag_list">
        <template #item="{ element, index }">
          <div class="draggable_row">{{ index + 1 }}、{{ element.name }}</div>
        </template>
      </draggable>
    </div>
    <template #footer>
      <el-button type="primary" @click="onSortSubmit" :loading="saving">确 定</el-button>
    </template>
  </el-dialog>

  <el-dialog
    title="房间排序(拖拽排序)"
    v-model="visibleStoreSortDialog"
    width="600px"
    draggable
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div>
      <draggable item-key="id" v-model="current.store_list">
        <template #item="{ element, index }">
          <div
            class="draggable_row"
          >{{ index + 1 }}、{{ element.house_no }}({{ element.landlord.name }})</div>
        </template>
      </draggable>
    </div>
    <template #footer>
      <el-button type="primary" @click="onStoreSortSubmit" :loading="store_saving">确 定</el-button>
    </template>
  </el-dialog>
  <edit-store
    :shops="shop_list"
    :item="current_store"
    @close="current_store = null"
    @success="loadData"
  ></edit-store>

  <store-dtl :id="view_store_id" @close="view_store_id=null"></store-dtl>
</template>

<script>
// import common_api from "@/http/common_api";
import EditStore from "./edit_store";
// import BatchSetGroup from "./batch_set_group.vue";
import Draggable from "vuedraggable";
import shop_api from "@/http/shop_api";
import StoreDtl from "./store_dtl";

export default {
  components: {
    StoreDtl,
    Draggable,
    EditStore,
  },
  data() {
    return {
      loading: false,
      saving: false,
      //保存房间排序
      store_saving: false,
      visibleDialog: false,
      visibleBatchDialog: false,
      //排序弹框
      visibleSortDialog: false,
      //房间排序弹框
      visibleStoreSortDialog: false,
      title: "",
      query: {
        shop_id: "",
      },
      form: {},
      rules: {
        shop_id: [{ required: true, message: "请选择门店", trigger: "blur" }],
        name: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      //门店列表
      shop_list: [],
      //所有的分组列表数据
      group_list: [],
      //数据列表
      data_list: [],
      //勾选store id集合
      checkedStores: [],
      //设置的表单
      setForm: {},
      //设置时选择分组
      group_options: [],
      set_rules: {
        groupIds: [{ required: true, message: "请选择", trigger: "blur" }],
      },

      //批量设置选择门店时存储的房源数据
      store_list: [],
      //排序拖拽列表
      drag_list: [],
      //查询详情的id
      view_store_id: "",
      //当前
      current: null,
      //当前编辑的房间
      current_store: null,
      //房间总数
      room_count_text: "",
      //可以编辑房型
      is_can_edit: false,
      //可以删除房型
      is_can_del: false,
      is_can_sort: false,
      //可以编辑房间
      is_can_edit_store: false,
      //可以删除房间
      is_can_del_store: false,
      is_can_sort_store: false,
    };
  },
  created() {
    shop_api.get_my_shops().then((res) => {
      if (res.code == 0) {
        this.shop_list = res.data;
      }
    });
    this.loadData();

    this.is_can_edit = this.$power("seller/v1/HouseStore/huXing/edit");
    this.is_can_del = this.$power("seller/v1/HouseStore/huXing/del");
    this.is_can_sort = this.$power("seller/v1/HouseStore/huXing/sort");
    this.is_can_edit_store = this.$power("seller/v1/houseStore/edit");
    this.is_can_del_store = this.$power("seller/v1/houseStore/del");
    this.is_can_sort_store = this.$power("seller/v1/houseStore/sort");
  },
  methods: {
    /**
     * 打开弹出排序框
     */
    openDraggable(list) {
      this.drag_list = list.map((item) => {
        return {
          id: item.id,
          name: item.name,
        };
      });
      this.visibleSortDialog = true;
    },

    /**
     * 排序提交保存
     */
    onSortSubmit() {
      this.saving = true;
      this.$http
        .post("seller/v1/houseStore/huXing/sort", this.drag_list)
        .then((res) => {
          if (res.code == 0) {
            this.loadData();
            this.visibleSortDialog = false;
          }
        })
        .finally(() => (this.saving = false));
    },

    /**
     * 房间排序
     */
    onStoreSort(item) {
      this.current = Object.assign({}, item);
      this.visibleStoreSortDialog = true;
    },

    /**
     * 提交保存房间排序
     */
    onStoreSortSubmit() {
      if (!this.current) {
        return;
      }
      var storeIds = [];
      this.current.store_list.forEach((x) => {
        storeIds.push(x.id);
      });
      //huxing/store_sort
      this.store_saving = true;

      this.$http
        .post(
          "seller/v1/houseStore/huXing/store_sort?groupId=" + this.current.id,
          storeIds
        )
        .then((res) => {
          if (res.code == 0) {
            this.loadData();
            this.visibleStoreSortDialog = false;
          }
        })
        .finally(() => (this.store_saving = false));
    },

    /**
     * 拖拽更改房间户型
     */
    dragChange(e) {
      if (e.added) {
        this.data_list.forEach((x) => {
          x.store_list.forEach((item) => {
            if (x.id != item.group_id) {
              this.$http.post("seller/v1/houseStore/changeStoreHuXing", {
                id: item.id,
                group_id: x.id,
              });
            }
          });
        });
      }
    },

    /**
     * 新增、修改
     */
    onEdit(item) {
      if (!item) {
        this.form = {};
        this.title = "新增";
      } else {
        this.title = "修改";
        this.form = Object.assign({}, item);
        delete this.form.shop;
        delete this.form.store_list;
        // this.shopChange(item.shop_id);
      }
      this.visibleDialog = true;
    },

    /**
     * 获取所有的户型房间数据
     */
    loadData() {
      this.loading = true;
      this.checkedStores = [];
      this.$http
        .get("seller/v1/houseStore/huXing/store")
        .then((res) => {
          if (res.code == 0) {
            this.data_list = res.data;
            this.temp_data_list = res.data;
            var count = 0;
            this.data_list.forEach((item) => {
              count += item.store_list.length;
            });
            this.room_count_text = `(共${count}间)`;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * 删除
     */
    onDelete(item) {
      this.$http
        .get("seller/v1/HouseStore/huXing/del?id=" + item.id)
        .then((res) => {
          if (res.code == 0) {
            this.loadData();
          }
        });
    },

    /**
     * 提交保存
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.$http
            .post("seller/v1/HouseStore/huXing/edit", this.form)
            .then((res) => {
              if (res.code == 0) {
                this.visibleDialog = false;
                this.$message.success(res.msg);
                this.loadData();
              }
            })
            .finally(() => {
              this.saving = false;
            });
        }
      });
    },

    /**
     * 删除房间
     * @param {*} item
     */
    onDeleteStore(item) {
      this.$http.get("seller/v1/houseStore/del?id=" + item.id).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.loadData();
        }
      });
    },

    /**
     * 查看详情
     * @param {*} id 
     */
    viewStoreDtl(id){
      this.view_store_id= id;
    },

    /**
     * 新增、修改
     */
    editStore(item) {
      this.current_store = {
        // group_id: hx.id,
      };
      if (item) {
        this.current_store.id = item.id;
      }
    },
  },
};
</script>

<style  scoped>
.store_group_box {
  display: flex;
  flex-grow: 1;
  height: 100%;
  flex-direction: column;
}
.store_group_box .header {
  height: 100px;
  background: #ebeef5;
  width: 100%;
  display: flex;
}

.store_group_box .header .header_group {
  width: 360px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.store_group_box .header .header_group .row {
  display: flex;
  align-items: center;
  flex-grow: 1;
  width: 100%;
}
.store_group_box .header .header_group .group_title {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid white;
  border-right: 1px solid white;
}
.store_group_box .header .header_group .row .col {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  border-right: 1px solid white;
  height: 100%;
}

.store_group_box .header .header_house_no {
  flex-grow: 1;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.store_group_box .body {
  flex-grow: 1;
  width: 100%;
  height: 1px;
}

.tree {
  border: 1px solid #ebeef5;
}
.tree_row {
  display: flex;
  /* align-items: center; */
  position: relative;
}
.tree_row {
  border-bottom: 1px solid #ebeef5;
  min-height: 60px;
}
.tree_row .group_header {
  width: 180px;
  border-right: 1px solid #ebeef5;
  padding: 20px;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
  background: white;
}
.tree_row .group_header {
  font-size: 14px;
  line-height: 14px;
}

.tree_row .group_bd {
  flex-grow: 1;
  widows: 1px;
}
.tree_row:last-child {
  border-bottom: none;
}
.tree_row.children .group_header {
  left: 180px;
}

.child_row {
  border-bottom: 1px solid #ebeef5;
  padding: 20px;
  margin-left: 360px;
}
.child_row:last-child {
  border-bottom: none;
}

/* 排序 */
.draggable_row {
  height: 50px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--el-border-color);
  cursor: pointer;
}

/* 房间 */
.store_row {
  display: flex;
  align-items: center;
  padding: 8px 0;
  cursor: pointer;
  border-bottom: 1px solid var(--el-table-border-color);
}
.store_row:first-child {
  padding: 0 0 8px;
}
.store_row:last-child {
  border: none;
  padding: 8px 0 0;
}

.store_row .house_id {
  width: 100px;
}
.store_row .house_no {
  width: 260px;
  padding-right: 10px;
}
.store_row .date {
  width: 180px;
}
.store_row .status {
  width: 80px;
}
</style>